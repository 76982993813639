import { Augmentation, Item } from '@discretize/gw2-ui-new';
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import {
  calculateRequest,
  getError,
  getResult,
} from '../../../state/fractals/god';
import EmbossedCard from '../../EmbossedCard';
import Message from "../../Message";
import Link from '../../navigation/Link';
import RelicsCalculatorImport from './RelicsCalculatorImport';
import { MIST_ATTUNEMENTS, Matrix, Page, Pristine, Relic } from './RelicsData';
import RelicsOutputTable from './RelicsOutputTable';

const useStyles = makeStyles()((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  input: {
    padding: theme.spacing(2),
  },
  inputLabel: {
    marginBottom: theme.spacing(1),
  },
  result: {
    margin: theme.spacing(2),
  },
  highlight: {
    color: theme.palette.primary.light,
    fontSize: 'inherit',
  },

  tableGridItem: {
    overflowX: 'auto',
  },
}));

function RelicsCalculator() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const result = useSelector(getResult);
  const error = useSelector(getError);

  const {
    mistAttunements,
    daily: {
      relics: dailyRelics,
      pristines: dailyPristines,
      matrices: dailyMatrices,
      pages: dailyPages,
    } = {},
  } = result ?? {};

  const [state, setState] = React.useState({
    relics: 0,
    pristines: 0,
    matrices: 0,
    augment: 0,
    journals: 0,
    pages: 0,
    cm100: true,
    cm99: true,
    cm98: true,
    t4s: true,
    recs: true,
    weekly: true,
    convertPots: true,
    extraRelicsEnable: false,
    extraRelicsValue: 0,
  });

  const calculate = () => {
    dispatch(calculateRequest({ ...state }));
  };

  const {
    relics,
    pristines,
    matrices,
    augment,
    journals,
    pages,
    cm100,
    cm99,
    cm98,
    t4s,
    recs,
    weekly,
    convertPots,
    extraRelicsEnable,
    extraRelicsValue,
  } = state;

  const handleChange =
    (name, boolean = false) =>
    ({ target: { value, checked } }) => {
      setState({ ...state, [name]: boolean ? checked : value });
    };

  useEffect(
    () => calculate(),
    [
      state.extraRelicsValue,
      relics,
      pristines,
      matrices,
      augment,
      journals,
      pages,
      cm100,
      cm99,
      cm98,
      t4s,
      recs,
      weekly,
      convertPots,
      extraRelicsEnable,
      extraRelicsValue,
    ],
  );

  const renderResult = () => {
    if (error) {
      return (
        <Grid item xs={12}>
          <Typography component="samp" color="error">
            {error}
          </Typography>
        </Grid>
      );
    }

    if (!result) {
      return (
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            <CircularProgress />
          </Typography>
        </Grid>
      );
    }

    const perDay = (
      <Typography
        variant="body1"
        color="textSecondary"
        align="center"
        gutterBottom
      >
        You earn {dailyRelics % 1 !== 0 && '~'}
        {Math.floor(dailyRelics)}x <Relic />, {dailyPristines}x <Pristine />,{' '}
        {dailyMatrices}x <Matrix /> and {dailyPages}x <Page /> per day.
      </Typography>
    );

    if (!mistAttunements || mistAttunements.length === 0) {
      return (
        <Grid item xs={12}>
          <EmbossedCard className={classes.result}>
            <Typography variant="h5" align="center" gutterBottom>
              Congratulations, you already are a{' '}
              {MIST_ATTUNEMENTS[MIST_ATTUNEMENTS.length - 1].title}!
            </Typography>
            {perDay}
          </EmbossedCard>
        </Grid>
      );
    }

    return (
      <>
        <Grid item xs={12}>
          <EmbossedCard className={classes.result}>
            {mistAttunements[0].days === Number.POSITIVE_INFINITY ? (
              <Typography variant="h5" align="center" gutterBottom>
                Sorry, you will never be a {mistAttunements[0].title} :-(
              </Typography>
            ) : (
              <Typography variant="h5" align="center" gutterBottom>
                You will be a {mistAttunements[0].title} in{' '}
                <samp className={classes.highlight}>
                  {Math.ceil(mistAttunements[0].days).toLocaleString()}
                </samp>{' '}
                days!
              </Typography>
            )}
            {perDay}
          </EmbossedCard>
        </Grid>

        <Grid item xs={12} className={classes.tableGridItem}>
          <RelicsOutputTable mistAttunements={mistAttunements} />
        </Grid>
      </>
    );
  };

  const MyDailies = [
    {
      checked: cm100,
      value: 'cm100',
      label: 'Sunqua Peak CM',
    },
    {
      checked: cm99,
      value: 'cm99',
      label: 'Shattered Observatory CM',
    },
    {
      checked: cm98,
      value: 'cm98',
      label: 'Nightmare CM',
    },
    {
      checked: t4s,
      value: 't4s',
      label: 'Daily T4 Fractals',
    },
    {
      checked: recs,
      value: 'recs',
      label: 'Daily Recommended Fractals',
    },
    {
      checked: weekly,
      value: 'weekly',
      label: 'Weekly T4s',
    },
    {
      checked: convertPots,
      value: 'convertPots',
      label: 'Convert potions',
    },
  ];

  return (
    <div className={classes.root}>
      <Message>
        This calculator is outdated. A more up-to-date version can be found{" "}
        <Link to="https://next.discretize.eu/augmentations">here</Link>.
      </Message>
      <Grid container spacing={5} justifyContent="space-around">
        <Grid item xs={12} sm={10} md={6} lg={5}>
          <Paper className={classes.input}>
            <FormControl
              component="fieldset"
              variant="outlined"
              fullWidth
              margin="normal"
            >
              <FormLabel component="legend" className={classes.inputLabel}>
                My current account values
              </FormLabel>

              <FormGroup>
                <FormControl fullWidth margin="dense" variant="standard">
                  <InputLabel htmlFor="augment">
                    Mist Attunement Tier
                  </InputLabel>

                  <Select
                    variant="standard"
                    value={augment}
                    onChange={handleChange('augment')}
                    inputProps={{
                      id: 'augment',
                    }}
                  >
                    <MenuItem value={0}>
                      <em>None</em>
                    </MenuItem>
                    {MIST_ATTUNEMENTS.map(({ name, title }, index) => (
                      <MenuItem value={index + 1} key={name}>
                        <Augmentation
                          name={name}
                          disableLink
                          style={{ marginRight: 4 }}
                        />{' '}
                        - {title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  id="relics"
                  label="Fractal Relics"
                  value={relics}
                  onChange={handleChange('relics')}
                  margin="dense"
                  fullWidth
                  type="number"
                  variant="standard"
                />

                <TextField
                  id="pristines"
                  label="Pristine Fractal Relics"
                  value={pristines}
                  onChange={handleChange('pristines')}
                  margin="dense"
                  fullWidth
                  type="number"
                  variant="standard"
                />

                <TextField
                  id="matrices"
                  label="Integrated Fractal Matrices"
                  value={matrices}
                  onChange={handleChange('matrices')}
                  margin="dense"
                  fullWidth
                  type="number"
                  variant="standard"
                />

                <TextField
                  id="journals"
                  label="Fractal Journals"
                  value={journals}
                  onChange={handleChange('journals')}
                  margin="dense"
                  fullWidth
                  type="number"
                  variant="standard"
                />

                <TextField
                  id="pages"
                  label="Fractal Research Pages"
                  value={pages}
                  onChange={handleChange('pages')}
                  margin="dense"
                  fullWidth
                  type="number"
                  variant="standard"
                />
              </FormGroup>
            </FormControl>

            <RelicsCalculatorImport
              onImport={({
                augment: importedAugment,
                relics: importedRelics,
                pristines: importedPristines,
                matrices: importedMatrices,
                journals: importedJournals,
                pages: importedPages,
              }) => {
                setState({
                  ...state,
                  augment: importedAugment,
                  relics: importedRelics,
                  pristines: importedPristines,
                  matrices: importedMatrices,
                  journals: importedJournals,
                  pages: importedPages,
                });
              }}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={10} md={5}>
          <Paper className={classes.input}>
            <FormControl
              component="fieldset"
              variant="outlined"
              fullWidth
              margin="normal"
            >
              <FormLabel component="legend" className={classes.inputLabel}>
                My dailies
              </FormLabel>

              <FormGroup>
                {MyDailies.map(({ checked, value, label }) => (
                  <FormControlLabel
                    key={value}
                    control={
                      <Checkbox
                        onChange={handleChange(value, true)}
                        value={value}
                        color="primary"
                        {...(value === 'convertPots'
                          ? {
                              disabled: !recs,
                              checked: recs && convertPots,
                            }
                          : { checked })}
                      />
                    }
                    label={label}
                  />
                ))}

                <FormControlLabel
                  label=""
                  control={
                    <>
                      <Checkbox
                        onChange={handleChange('extraRelicsEnable', true)}
                        value="extraRelicsEnable"
                        color="primary"
                        checked={extraRelicsEnable}
                      />
                      <TextField
                        id="extraRelicsValue"
                        disabled={!extraRelicsEnable}
                        label="Add/Subtract arbitrary relics from daily income"
                        value={extraRelicsValue}
                        onChange={(e) => {
                          setState({
                            ...state,
                            extraRelicsValue: e.target.value,
                          });
                        }}
                        margin="dense"
                        fullWidth
                        type="number"
                        variant="standard"
                      />
                    </>
                  }
                />
              </FormGroup>
            </FormControl>
          </Paper>
        </Grid>

        {renderResult()}

        <Grid item xs={12}>
          <Typography
            variant="body1"
            color="textSecondary"
            align="center"
            component="em"
          >
            Note: <Relic />s from <Item id={75409} />s or random potion drops
            are not included.
            <br />
            {mistAttunements && mistAttunements.length === 4 && (
              <>
                Also keep in mind that you need tier 2 of{' '}
                <Link to="https://wiki.guildwars2.com/wiki/Account_Augmentation">
                  all other augmentations
                </Link>{' '}
                to purchase Mist Attunement 1.
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default RelicsCalculator;
